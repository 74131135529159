body {
  background-color: #17181F;
  color: #ddd;
}

.heading h1{
  color: orangered;
}

main {
  max-width: 400px;
  margin: 20px auto;
}

h1 {
  text-align: center;
  color: #fff;
  margin: 0;
  font-size: 2.5rem;
}
h1 span{
  font-size: 1rem;
}

form {
  margin-top: 20px;
}

form input {
  width: 100%;
  background-color: transparent;
  color: #ddd;
  border: 2px solid #30313d;
  padding: 10px 5px;
  border-radius: 5px;
}

form input[type='datetime-local'] {
  color: #777;
}

form div.basic {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
}

form button {
  width: 100%;
  margin-top: 5px;
  border: 0;
  border-radius: 5px;
  padding: 10px;
  background-color: #ddd;
  cursor: pointer;
  transition: background-color 0.3s;
}

form button:hover {
  background: #218838;
  color: #fff;
}

div.transactions {
  margin-top: 15px;
}

div.transaction {
  display: flex;
  justify-content: space-between;
  padding: 7px 0;
  border-top: 1px solid #30313d;
  transition: background-color 0.3s, transform 0.3s;
}

div.transaction:first-child {
  border-top: 0;
}

div.transaction:hover {
  background: #30313d;
  transform: scale(1.02);
}

div.transaction div.name {
  font-size: 1.2rem;
}

div.transaction div.description {
  font-size: .7rem;
  color: #85b6d0;
}

div.right {
  text-align: right;
}

div.price.green {
  color: #1c1;
}

div.price.red {
  color: #c11;
}

div.transaction div.datetime {
  font-size: 0.7rem;
  color: #dc8d8d;
}



